/* custom-tree.css */

.node__root>circle {
  fill: #81c25d;
}

.node__branch>circle {
  fill: #81c25d;
}

.node__leaf>circle {
  fill: #81c25d;
}
